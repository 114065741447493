import * as THREE from 'three'
import Experience from './Experience.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.setInstance()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 140)
        this.instance.position.set(0, 0, 20)
        this.instance.name = 'Camera'
        this.scene.add(this.instance)
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }
    update(delta,parallaxX,parallaxY)
    {
        const scale = 2
        this.instance.position.x += (parallaxX - this.instance.position.x) * scale * delta
        this.instance.position.y -= (parallaxY + this.instance.position.y) * scale * delta
    }
}