import * as THREE from "three";

import EventEmitter from "./Utils/EventEmitter";

export default class Raycaster extends EventEmitter {

  constructor(experience) {
    super()
    this.selected = [];
    this.experience = experience;
    this.camera = experience.camera;
    this.world = experience.world;
    this.mouse = experience.mouse;
    this.setInstance();
  }

  setInstance() {
    this.instance = new THREE.Raycaster();
    this.instance.setFromCamera(this.mouse.cursor, this.camera.instance);
  }

  click() {
    this.instance.setFromCamera(this.mouse.cursor, this.camera.instance);
    
    let intersects = this.instance.intersectObjects(
      this.experience.scene.children
    );
    if (intersects.length > 0) {
      for (let i = 0; i < intersects.length; i++) 
        {
          if (
            intersects[i].object !== null &&
            intersects[i].object.type === "Mesh" &&
            intersects[i].object.name.includes('card')
          ) {
            this.world.hit_object(intersects[i].object)
          }
          else if (
            intersects[i].object !== null &&
            intersects[i].object.type === "Mesh" &&
            intersects[i].object.name.includes('button_link')
          ) {
            this.world.link()
          }
          else if (
            intersects[i].object !== null &&
            intersects[i].object.type === "Mesh" &&
            intersects[i].object.name.includes('button_reset')
          ) {
            this.world.reset()
          }
        }
    }
  }

  update(intersectedObject) {
  }
}
